@font-face {
  font-family: 'RobottoMono';
  src: local('RobottoMono'), url(./fonts/RobotoMono-Regular.ttf) format('truetype');
}
$font-color: white;
$active-color: green;

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(./parchment.jpg);
  min-height: 100vh;
  color: $font-color;
  font-family: RobottoMono;
}


/* HEADER */

@media screen and (min-width: 500px) {
  .logo {
    width: 450px;
    pointer-events: none;
  }

  .slogan {
    width: 500px;
    pointer-events: none;
    transform: translateY(-10px);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(8px + 1.75vmin);
    margin: 10px 0px;
  }
}

@media screen and (orientation: portrait) and (max-width: 499px) {
  .logo {
    width: calc(200px + 20vmin);
    pointer-events: none;
  }

  .slogan {
    width: calc(175px + 40vmin);
    pointer-events: none;
    transform: translateY(-10px);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(8px + 1.25vmin);
    margin: 10px 0px;
  }
}

.header-contact {
  color: $font-color;
  padding: 0 0 10px 0;
}

.header-contact > div {
  color: $font-color;
  font-weight: 600;
}

a.header-link {
  color: $font-color;
  font-weight: 600;
  text-decoration: none;
  &:visited,
  &:link {
    color: $font-color;
  }
  &:hover {
    color: darkgreen;
  }
}

.header-hours {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
}


/* ANNOUNCEMENTS */

.announcements {
  display: none;
  border: 3px $active-color dashed;
  min-width: 30vw;
  max-width: 50vw;
}

.announcements > p {
  font-size: calc(6px + 1.2vmin);
  font-weight: bold;
  padding: 0px 20px;
  text-align: justify;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}


/* NAVIGATION */

.navigation {
  margin-top: 20px;
  width: auto;
  min-width: 20vw;
}


/* ITEM */

.item {
  font-size: calc(10px + 1vmin);
  border-left: 2px $font-color dashed;
  border-right: 2px $font-color dashed;
  padding-left: calc(10px + 1vmin);
  padding-right: calc(10px + 1vmin);
  margin: 11px 0;
  font-weight: bolder;
}

.link-to {
  &:link,
  &:visited {
    color: $font-color;
    text-decoration: none;
  }
  &:hover {
    color: $active-color;
  }
}

.link-to-active {
  color: green;
  font-size: 20px;
  &:link,
  &:visited {
    color: none;
    text-decoration: none;
  }
  &:hover {
    color: $active-color;
  }
}

.item:hover {
  color: $active-color;
  border-left: 2px $active-color dashed;
  border-right: 2px $active-color dashed;
}

.food-item-price {
  font-weight: bold;
  font-size: calc(9px + 1vmin);
}

.food-item-desc {
  font-size: calc(8px + 1vmin);
}


/* DETAIL */

.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 2px $font-color solid;
  border-right: 2px $font-color solid;

  /* transition: background 250ms ease-in-out,
                transform 150ms ease; */
}


/* FOOD & CATERING MENU */

.food-menu,
.catering-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.food-category {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 90vw;
  max-width: 95vw;
}

.food-category-card,
.catering-category-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 95vw;
  font-weight: bold;
  padding-bottom: 10px
}

span.category-title {
  color: $active-color;
  font-size: calc(5px + 2vmin);
  font-weight: bold;
  &:hover {
    transform: scaleX(.97);
    cursor: pointer;
  }
}

section.category-items {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95vw;
}

.food-item-card {
  width: 25vw;
  text-align: left;
  margin: .5vh 3vw;
}

.food-item-title {
  color: $font-color;
  font-weight: bold;
  font-size: calc(5px + 2vmin);
  margin-bottom: 0px;
}

.food-item-price {
  margin-bottom: 0px;
  margin-top: 0px;
}

.food-item-desc {
  margin-top: 0px;
}


/* ORDER */


@media screen and (min-width: 499px) {
  .order {
    width: 35vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}
@media screen and (max-width: 500) {
  .order {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

.order {
  width: 30vw;
  min-width: 300px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.order-button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.order-button-group > p {
  font-size: calc(8px + 1.5vmin);
  font-weight: bolder;
}

/* ABOUT US */

@media screen and (orientation: portrait) and (max-width: 499px ) {

  .about-us {
    max-width: 85vw;
  }

  .about-us > p {
    font-size: calc(8px + 1vmin);
    font-weight: bold;
    padding: 0px 10px;
    text-align: center;
  }

}

@media screen and (min-width: 500px) {

  .about-us {
    min-width: 35vw;
    max-width: 75vw;
  }

  .about-us > p {
    font-size: calc(8px + 1.1vmin);
    font-weight: bold;
    padding: 0px 30px;
    text-align: center;
  }

}



/* SOCIAL & REVIEWS */

.social {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 30px 0px;
}

.reviews {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 30px 0px;
}

.social-icon,
.review-icon {
  margin: 0 10px;
  transform: scale(.9);
  &:hover {
    transform: scale(1);
    animation: 100ms linear grow;
  }
  &:not(:hover) {
    transform: scale(.9);
    animation: 100ms linear shrink;
  }
}


/* GALLERY */

.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  width: 85vw;
}

.gallery > img {
  min-width: 32.5vmin;
  max-width: 42.5vmin;
  min-height: 37.5vmin;
  max-height: 42.5vmin;
  margin: 2vmin 2vmin;
  box-shadow: 0 0 15px 3px beige;
  &:hover {
    box-shadow: 0 0 15px 5px beige;
  }
}


/* NEWSLETTER */

.newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  max-width: 500px;
  min-width: 200px;;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: calc(5px + 1.5vmin);
}

.newsletter > p {
  text-align: justify;
}

@media screen and (min-width: 500px) {
  .email-form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .email-label {
    font-size: calc(10px + 1.4vmin);
    padding-right: 5px;
  }

  .email-input,
  [type='text'] {
    height: 4vmin;
    width: 25vw;
    min-width: 170px;
    border-radius: 5px;
  }
}

@media screen and (orientation: portrait) and (max-width: 499px) {
  .email-form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .email-label {
    font-size: calc(10px + 1.4vmin);
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .email-input,
  [type='text'] {
    height: 4vmin;
    min-height: 20px;
    width: 40vw;
    min-width: 120px;
    min-width: 120px;
    margin-left: 10px;
    border-radius: 2.5px;
  }
}

.email-input,
[type="text"] {
  border: none;
  font-size: calc(6px + 1.4vmin);
  font-family: RobottoMono;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.newsletter-messages {
  color: $font-color;
  font-family: RobottoMono;
  font-size: calc(8px + 1.2vmin);
  font-weight: bolder;
  margin: 10px 0 20px 0;
  border-top: 1px solid $font-color;
  border-bottom: 1px solid $font-color;
}



/* BUTTONS */

@media screen and (min-width: 500px) {
  .submit-button {
    margin: 15px 0;
    height: auto;
    min-height: 2rem;
    width: 150px;
  }
}

@media screen and (max-width: 499px) {
  .submit-button {
    margin: auto;
    height: auto;
    min-height: 1.6rem;
    width: 100%;
  }
}

.submit-button {
  min-width: 5rem;

  display: block;
  border: none;
  border-radius: 5px;
  padding: 0rem .6rem;
  text-decoration: none;
  background: green;
  color: $font-color;
  font-family: RobottoMono;
  font-size: calc(6px + 1.2vmin);
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  transition: background 100ms ease-in-out,
              transform 50ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.order-button {
  height: auto ;
  width: auto;
  min-width: 5rem;
  min-height: 2rem;
  display: block;
  border: none;
  border-radius: 5px;
  padding: 0rem .6rem;
  margin: auto 0;
  text-decoration: none;
  background: green;
  color: $font-color;
  font-family: RobottoMono;
  font-size: calc(6px + 1.2vmin);
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  transition: background 100ms ease-in-out,
              transform 50ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.order-button:hover,
.order-button:focus,
.submit-button:hover,
.submit-button:focus {
  background: darkgreen;
}

.order-button:focus,
.submit-button:focus {
  outline: none;
  outline-offset: none;
}

.order-button:active,
.submit-button:active {
  transform: scale(0.95);
}


/* FLOATING SCROLL BUTTON */

/* Fonts */

@import url(https://fonts.googleapis.com/css?family=Indie+Flower);


/* Button */

@media screen and (min-width: 500px) {
  .btn-container {
    position: sticky;
    right: 4rem;
    bottom: 3rem;
    align-self: flex-end;
    margin: 0px;
    padding: 0px;
  }

  .btn {
    width: 50px;
  	height: 50px;
    line-height: 50px;
    font-size: 25px;
  }
}

@media screen and (max-width: 499px) {
  .btn-container {
    position: sticky;
    right: 1rem;
    bottom: 1rem;
    align-self: flex-end;
    margin: 0px;
    padding: 0px;
  }

  .btn {
    width: 35px;
  	height: 35px;
    line-height: 37.5px;
    font-size: 20px;
  }
}


.btn {
	background-color: #FF6138;
  border-radius: 10px;
	color: #fff;
  font-family: RobottoMono;
  font-weight: bolder;
	text-decoration: none;
	text-align: center;
	display: inline-block;
  position: inherit;

  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
          transition-property: transform;
  -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
          transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
          transform: translateY(-6px);
  -webkit-animation-name: hover;
          animation-name: hover;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;

  &:before{
  	pointer-events: none;
	  position: absolute;
	  z-index: -1;
	  content: '';
	  top: 100%;
	  left: 5%;
	  height: 10px;
	  width: 90%;
	  opacity: 0;
	  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
	  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);

	  -webkit-transition-duration: 0.3s;
	          transition-duration: 0.3s;
	  -webkit-transition-property: -webkit-transform, opacity;
	          transition-property: transform, opacity;
	  opacity: .4;
	  -webkit-transform: translateY(6px);
	      -ms-transform: translateY(6px);
	          transform: translateY(6px);
	  -webkit-animation-name: hover-shadow;
	          animation-name: hover-shadow;
	  -webkit-animation-duration: 1.5s;
	          animation-duration: 1.5s;
	  -webkit-animation-delay: .3s;
	          animation-delay: .3s;
	  -webkit-animation-timing-function: linear;
	          animation-timing-function: linear;
	  -webkit-animation-iteration-count: infinite;
	          animation-iteration-count: infinite;
	  -webkit-animation-direction: alternate;
	          animation-direction: alternate;
  }
    animation: 200ms ease-in grow;

	&:hover{
		// background-color: #e16e50;
    // background: rgba(255, 98, 55, .8);
    transform: scale(1);
	}
}

.btn > p {
  margin: 50px 0;
  color: #fff;
  font-family: 'Indie Flower', cursive;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

// ----------------------- //
// ------[KEYFRAMES]------ //
// ----------------------- //

@keyframes hover {
  50% {
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
            transform: translateY(-6px);
  }
}

@-webkit-keyframes hover-shadow {
  0% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
    opacity: .4;
  }

  50% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
    opacity: .4;
  }
}

@keyframes hover-shadow {
  0% {
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
    opacity: .4;
  }

  50% {
    -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
            transform: translateY(3px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
    opacity: .4;
  }
}

@-webkit-keyframes hover {
  50% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
  }
}

@keyframes hover {
  50% {
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
            transform: translateY(-6px);
  }
}

@keyframes grow {
  0% {
    -webkit-transform: scale(.9);
        -ms-transform: scale(.9);
            transform: scale(.9);
  }

  100% {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(.9);
        -ms-transform: scale(.9);
            transform: scale(.9);
  }
}
